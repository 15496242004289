import React, { Component } from "react"
import Image from "gatsby-image"
import { graphql } from "gatsby"

import s from "./httparty.module.scss"

//import ShowWindowDimensions from "../components/ShowWindowDimensions"

class HTTParty extends Component {
   constructor(props) {
      super(props)
   }

   componentDidMount() {

   }

   componentDidUpdate(prevProps, prevState) {

   }

   componentWillUnmount() {
   }

   updateDimensions = () => {
   }

   render() {



      return (

        <div className={s.sepage}>
        <div className={s.secontainer}>

        <Image fluid={this.props.data.file.childImageSharp.fluid} />


        <h1><a href="https://youtu.be/UdesVtcvm8c" target="_blank">RAVE PEARCE LIVE FROM 8PM</a></h1>
        <h2><a href="https://youtu.be/UdesVtcvm8c" target="_blank">Click here</a></h2>
        </div>

        </div>
      )
   }
}

export default HTTParty

export const data = graphql`
   query {
      file(relativePath: { eq: "httparty/self.jpg" }) {
         childImageSharp {
            fluid {
               base64
               aspectRatio
               originalImg
               originalName
               presentationHeight
               presentationWidth
               sizes
               src
               srcSet
               srcSetWebp
               srcWebp
               tracedSVG
            }
         }
      }
   }
`
